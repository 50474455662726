import { Card, NonIdealState } from "@blueprintjs/core";

import styles from "./scss/Fallback.module.scss";

interface FallbackProps {
  error: Error;
  componentStack: string | null;
  resetError(): void;
}

const Fallback = ({ error, componentStack, resetError }: FallbackProps) => (
  <div className={styles.errorWrapper}>
    <div />
    <div>
      <Card>
        <NonIdealState
          icon="error"
          title="Oops! Something went wrong..."
          description={error.toString()}
          // action={this.state.showAction ? action : undefined}
          // layout={this.state.layout}
        >
          <pre>{componentStack}</pre>
        </NonIdealState>
      </Card>
    </div>
    <div />
  </div>
);

export default Fallback;
