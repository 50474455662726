import { NonIdealState } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";

import styles from "./scss/notFound.module.scss";

interface NotFoundPageProps {
  // eslint-disable-next-line react/require-default-props
  placeholder?: string;
}

const NotFoundPage = ({ placeholder = "placeholder" }: NotFoundPageProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.notFound}>
      <NonIdealState title={t("Page not found!")} icon="error" />
    </div>
  );
};

export default NotFoundPage;
