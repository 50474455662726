import { IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useState } from "react";
import { RiEye2Line, RiEyeCloseFill } from "react-icons/ri";

interface PasswordInputProps {
  name: string;
  value: string;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const PasswordInput = ({ name, onChange, value }: PasswordInputProps) => {
  const [show, setShow] = useState(false);

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? "text" : "password"}
        id={name}
        name={name}
        onChange={onChange}
        value={value}
      />
      <InputRightElement>
        <IconButton
          aria-label="show"
          icon={show ? <RiEye2Line /> : <RiEyeCloseFill />}
          size="sm"
          variant="ghost"
          onClick={() => setShow((prevState) => !prevState)}
        >
          {show ? "Hide" : "Show"}
        </IconButton>
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
